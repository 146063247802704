import React from 'react';
import TwoColumnComponent from '../components/TwoColumnComponent';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import SplitComponent from '../components/SplitComponent';
import webDev from '../assets/home/services/web-img.png';
import appDev from '../assets/home/services/app-img.png';
import UxDev from '../assets/home/services/uxui.png';
import client01 from '../assets/home/clients/truth.jpg';
import client02 from '../assets/home/clients/nsur.jpg';
import client03 from '../assets/home/clients/plays-number-game.jpg';
import client04 from '../assets/home/clients/premierxfree.jpg';
import client05 from '../assets/home/clients/snap.jpg';
import banner from '../assets/home/banner.png';
import mobileBanner from '../assets/home/banner-m.png';

const clients = [
  { src: client01, link: 'https://truth-network.io/' },
  { src: client02 },
  { src: client03, link: 'https://www.playnumbersgame.com/' },
  { src: client04 },
  { src: client05, link: 'https://www.snappartners.com/' },
]

const Home = () => {
  return (
    <>
      <div className='h-full'></div>
      <section className='px-4 sm:px-0 py-10 sm:py-0 w-full w-full mt-12'>
        {/* Image for mobile */}
        <img src={mobileBanner} alt='r-link mobile banner' className='sm:hidden' />

        {/* Image for larger screens */}
        <img src={banner} alt='r-link banner' className='h-[100vh] hidden sm:block w-full' />
      </section>

      <section className='text-center text-white p-4'>
        <h1 className='text-base sm:text-3xl text-backgroundSecondary'>Our Benefits</h1>
        <h2 className='text-lg sm:text-[40px] uppercase sm:my-2'>Efficient Node Hosting</h2>
        <h6 className='font-light sm:text-base text-sm text-textSecondary'>Discover unparalleled ease and security</h6>
      </section>
      <section className='flex flex-wrap'>
        <div className='flex flex-col sm:flex-row'>
          <TwoColumnComponent
            icon={TouchAppIcon}
            heading="Ease of Use"
            paragraph="Deploy nodes in clicks, not code. Enjoy blockchain participation without the tech complexities."
          />
          <TwoColumnComponent
            icon={ShieldOutlinedIcon}
            heading="Security"
            paragraph="Sleep soundly with state-of-the-art security ensuring your nodes are protected around the clock."
          />
          <TwoColumnComponent
            icon={SpeedOutlinedIcon}
            heading="Performance"
            paragraph="Enjoy ultra-fast node operations with our high-performance servers for seamless blockchain activity."
          />
          <TwoColumnComponent
            icon={WorkspacePremiumOutlinedIcon}
            heading="Reliability"
            paragraph="Count on us for unmatched uptime, keeping your nodes online when it matters most."
          />
        </div>
        <div className='flex flex-col sm:flex-row'>
          <TwoColumnComponent
            icon={SupportAgentOutlinedIcon}
            heading="Support"
            paragraph="Get expert assistance at your fingertips, making node management worry-free."
          />
          <TwoColumnComponent
            icon={MonetizationOnOutlinedIcon}
            heading="Cost-Effectiveness"
            paragraph="Economize with our cloud servers—less cost, more blockchain presence."
          />
          <TwoColumnComponent
            icon={QueryStatsOutlinedIcon}
            heading="Scalability"
            paragraph="Expand your blockchain reach effortlessly. Scale your node hosting as your needs grow."
          />
          <TwoColumnComponent
            icon={RadarOutlinedIcon}
            heading="Decentralization"
            paragraph="Strengthen the blockchain. Our hosting supports a decentralized future."
          />
        </div>
      </section>
      <section>
        <div className='flex flex-col sm:flex-row sm:m-8 mt-4 sm:mt-12 w-[96%]'>
          <div className='flex flex-col items-center justify-center p-4 sm:p-10 w-full sm:w-1/2'>
            <div className='border-[1px] border-white hostingtSection w-full sm:w-[70%] flex flex-col items-center justify-center p-4 sm:p-10'>
              <div className='p-2 border-4 border-backgroundSecondary rounded-full m-2'>
                <GppGoodOutlinedIcon sx={{ color: '#F55327!important', fontSize: '4rem' }} />
              </div>
              <h1 className='text-2xl text-backgroundSecondary mb-2'>Extra Secure</h1>
              <p className='text-center text-white'>
                Extra Secure Our cutting-edge security ensures your operations are safe and sound, 24/7. Experience unmatched protection.
              </p>
            </div>
            <a href="https://hosting.aisd.io/login" className="bg-backgroundSecondary hover:bg-backgroundSecondaryDark hover:scale-[1.008] text-white p-4 text-2xl m-4 mt-8 sm:w-1/2 text-center">HOST NODES</a>
          </div>
          <div className='w-full sm:w-1/2'>
            <h2 className='orangeBar'>Hosting prices</h2>
            <div>
              <h4 className='w-[99%] sm:w-1/2 text-2xl text-white p-2 border-2 border-white mb-4'>
                <DoneOutlinedIcon className='pr-2' sx={{ color: '#00FF5A', fontSize: '2.2rem' }} />
                $4.95 from 01 to 49
              </h4>
              <h4 className='w-[99%] sm:w-1/2 text-2xl text-white p-2 border-2 border-white mb-4'>
                <DoneOutlinedIcon className='pr-2' sx={{ color: '#00FF5A', fontSize: '2.2rem' }} />
                $3.95 from 50 to 99
              </h4>
              <h4 className='w-[99%] sm:w-1/2 text-2xl text-white p-2 border-2 border-white mb-4'>
                <DoneOutlinedIcon className='pr-2' sx={{ color: '#00FF5A', fontSize: '2.2rem' }} />
                $2.95 from 100 to 499
              </h4>
              <h4 className='w-[99%] sm:w-1/2 text-2xl text-white p-2 border-2 border-white mb-4'>
                <DoneOutlinedIcon className='pr-2' sx={{ color: '#00FF5A', fontSize: '2.2rem' }} />
                $1.95 from 500 to up
              </h4>
            </div>
            <h6 className='text-white pr-4'>*All prices indicated do not include taxes</h6>
          </div>
        </div>
      </section>
      <section>
        <div className='mt-4 sm:mt-0 sm:mb-20'>
          <h2 className='orangeBar uppercase'>More Services</h2>
        </div>
        <div>
          <SplitComponent
            image={webDev}
            heading="Web Development"
            text="We are specialized in the development of personalized websites, optimized for speed, security and user experience."
            link="/ourservices"
            linkText="Read More"
            styles="flex-col md:flex-row"
            boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
            insideBoxStyles="ml-4 sm:ml-20"
          />
          <SplitComponent
            image={appDev}
            heading="App Development"
            text="We create innovative mobile applications, which run smoothly on different platforms."
            link="/ourservices"
            linkText="Read More"
            styles="flex-col md:flex-row-reverse"
            boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
            insideBoxStyles="ml-4 sm:ml-20"
          />
          <SplitComponent
            image={UxDev}
            heading="UI/UX DESIGN"
            text="We help your company to improve its digital presence, by creating attractive and intuitive user interfaces that help usability."
            link="/ourservices"
            linkText="Read More"
            styles="flex-col md:flex-row"
            boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
            insideBoxStyles="ml-4 sm:ml-20"
          />
        </div>
      </section>
      <section className='mb-10'>
        <div className='mb-4 mt-12'>
          <h2 className='orangeBar uppercase'>Our clients</h2>
        </div>
        <div className='flex flex-row flex-wrap'>
          {clients.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={`Node ${index + 1}`}
              onClick={() => image.link && window.open(image.link, '_blank')}
              className="m-2 ml-4 w-[90%] sm:w-[18%] h-[25%] sm:h-[18%] object-cover rounded-lg border border-white hover:scale-[1.007] cursor-pointer"
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default Home;
